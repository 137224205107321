<template>
  <div>
    <hr class="kiosk-horizontal-line mb-2" />
    <div class="kiosk-pass-title mb-3 text-center">User authentication</div>
    <InfoBox
      v-if="state.reqResponse.message"
      class="border-0 v3-info-box danger w-100 mb-3"
      ><div class="v3-info-box-title v3-info-box-title-not-bold m-0 not-bold">
        {{ state.reqResponse.message }}
      </div></InfoBox
    >
    <CRow class="m-auto gap-3 mb-3">
      <CCol
        data-test-id="kiosk-login-methods-email"
        class="v3kiosk-login-methods"
        :class="{
          'v3kiosk-login-methods-active': state.authTypes.standart.isVisible
        }"
        @click="setLoginType('standart')"
      >
        <CCol class="v3kiosk-login-methods-box">
          <i class="ri-mail-line"></i>
        </CCol>
        <div class="v3kiosk-login-methods-text">Email</div>
      </CCol>
      <CCol
        class="v3kiosk-login-methods"
        :class="{
          'v3kiosk-login-methods-active': state.authTypes.qrcode.isVisible
        }"
        @click="setLoginType('qrcode')"
      >
        <CCol class="v3kiosk-login-methods-box">
          <i class="ri-bank-card-2-line"></i>
        </CCol>
        <div class="v3kiosk-login-methods-text">Card scan</div>
      </CCol>
      <CCol
        class="v3kiosk-login-methods"
        :class="{
          'v3kiosk-login-methods-active': state.authTypes.barcode.isVisible
        }"
        @click="setLoginType('barcode')"
      >
        <CCol class="v3kiosk-login-methods-box">
          <i class="ri-qr-scan-2-line"></i>
        </CCol>
        <div class="v3kiosk-login-methods-text">Code scan</div>
      </CCol>
    </CRow>

    <!-- Login email -->
    <div v-if="state.authTypes.standart.isVisible">
      <div class="mb-3">
        <Label> Email </Label>
        <div class="kiosk-custom-field d-flex">
          <div class="kiosk-custom-field-input">
            <InputField
              data-test-id="kiosk-login-email"
              :invalid-feedback="errors.email"
              v-model="state.form.email"
              :v-no-auto-fill-input="true"
              :autocomplete="false"
            />
          </div>
          <div class="kiosk-custom-field-select">
            <CustomSelect
              v-if="schoolDomains"
              :close-on-select="true"
              :searchable="false"
              v-model="state.activeDomain"
              :options="schoolDomains"
            />
          </div>
        </div>
      </div>
      <div class="mb-3">
        <Label> Kiosk passcode </Label>
        <InputField
          data-test-id="kiosk-login-passcode"
          class="w-full"
          v-model="state.form.code"
          :invalid-feedback="errors.code"
          :v-no-auto-fill-input="true"
          :autocomplete="false"
          input-type="password"
        />
      </div>
      <div class="d-flex justify-content-center gap-3">
        <BaseButton
          class="w-50 d-flex align-items-center justify-content-center"
          rounded
          @click="goToMainScreen"
        >
          Cancel
        </BaseButton>
        <LoadingButton
          data-test-id="kiosk-login-submit-button"
          class="w-50 d-flex align-items-center justify-content-center"
          :is-loading="state.isLoading"
          @click="login('standart')"
          rounded
          solid
        >
          Submit
        </LoadingButton>
      </div>
    </div>
    <!-- Login card -->
    <div v-if="state.authTypes.qrcode.isVisible">
      <div class="kiosk-login-auth-box mb-3">
        <div class="image-title mb-3">Swipe your card</div>
        <img
          class="image"
          src="@/assets/images/Kiosk-Swipe-Card.png"
          alt="swiper"
        />
      </div>
      <div class="mb-3">
        <Label> Swipe your card </Label>
        <InputField
          class="w-100"
          v-model="state.form.qrCode"
          :v-no-auto-fill-input="true"
          :autocomplete="false"
          input-type="password"
          :is-focused="true"
        />
      </div>
      <div>
        <BaseButton
          class="w-100 d-flex align-items-center justify-content-center"
          rounded
          @click="goToMainScreen"
        >
          Cancel
        </BaseButton>
      </div>
    </div>

    <!-- Login scan -->
    <div v-if="state.authTypes.barcode.isVisible">
      <div
        class="kiosk-login-auth-box mb-3 cursor-pointer"
        @click="launchScanner"
      >
        <div class="image-title mb-3">Click to scan Barcode or QR</div>
        <img
          class="image"
          src="@/assets/images/Kiosk-Barcode.png"
          alt="scanner"
        />
      </div>
      <div class="mb-3">
        <Label> Type in barcode/QR code </Label>
        <InputField
          class="w-100"
          v-model="state.form.barcode"
          :v-no-auto-fill-input="true"
          :autocomplete="false"
          input-type="password"
          :is-focused="true"
        />
      </div>
      <div>
        <BaseButton
          class="w-100 d-flex align-items-center justify-content-center"
          rounded
          @click="goToMainScreen"
        >
          Cancel
        </BaseButton>
      </div>
      <!-- Scanner Modal -->
      <CModal
        backdrop="static"
        size="lg"
        class="overflow-auto"
        :visible="state.scannerModalVisible"
        :close-on-backdrop="false"
      >
        <div
          class="p-4 scanner-modal d-flex align-items-center justify-content-between"
        >
          <div class="scanner-modal-container-text flex-1">
            Scan your Digital ID barcode/QR code
          </div>
          <div
            class="d-inline-block text-black p-1 lh-normal cursor-pointer"
            @click="closeScanner"
          >
            <i class="ri-close-line" />
          </div>
        </div>
        <div class="pb-4 px-4 h-100 text-center">
          <CSpinner
            v-show="state.isScannerLoading"
            color="primary"
            id="cloader"
          />
          <qrcode-stream
            :formats="['qr_code', 'code_128']"
            @detect="onDetect"
            @camera-on="onScannerLaunched"
          ></qrcode-stream>
        </div>
      </CModal>
    </div>
  </div>
</template>

<script>
import Label from "@/v3components/shared/Form/Label"
import CustomSelect from "@/v3components/shared/Form/CustomSelect"
import InputField from "@/v3components/shared/Form/InputField"
import BaseButton from "@/v3components/shared/Buttons/BaseButton"
import LoadingButton from "@/v3components/shared/Buttons/LoadingButton"
import { useStore } from "vuex"
import { onBeforeMount, computed, reactive, watch } from "vue"
import useVuelidate from "@vuelidate/core"
import { helpers } from "@vuelidate/validators"
import InfoBox from "@/v3components/shared/Alerts/InfoBox"
import { QrcodeStream } from "vue-qrcode-reader"

export default {
  name: "KioskUserLogin",
  components: {
    Label,
    CustomSelect,
    InputField,
    BaseButton,
    LoadingButton,
    InfoBox,
    QrcodeStream
  },
  props: ["activeKiosk"],
  emits: ["userLoggedIn", "tabChanged"],
  setup(props, { emit }) {
    const store = useStore()

    const state = reactive({
      isLoading: false,
      auth_type: "standart",
      codeLoginTimeOut: null,
      form: {
        email: null,
        code: null,
        qrCode: "",
        barcode: ""
      },
      activeDomain: null,
      reqResponse: {
        type: "",
        message: null,
        errors: {}
      },
      authTypes: {
        standart: {
          isVisible: true,
          icon: "flaticon-user"
        },
        qrcode: {
          isVisible: false,
          icon: "flaticon-credit-card"
        },
        barcode: {
          isVisible: false,
          icon: "flaticon-qr-code"
        }
      },
      isScannerLoading: true,
      scannerModalVisible: false
    })

    const validationMessages = {
      required: "This field is required"
    }

    const validations = {
      form: {
        email: {
          required: helpers.withMessage(validationMessages.required, () => {
            return state.authTypes.standart.isVisible
              ? state.form.email.length > 0
              : true
          })
        },
        code: {
          required: helpers.withMessage(validationMessages.required, () => {
            return state.authTypes.standart.isVisible
              ? state.form.code.length > 0
              : true
          })
        },
        qrCode: {
          required: helpers.withMessage(validationMessages.required, () => {
            return state.authTypes.qrcode.isVisible
              ? state.form.qrCode.length > 0
              : true
          })
        },
        barCode: {
          required: helpers.withMessage(validationMessages.required, () => {
            return state.authTypes.barcode.isVisible
              ? state.form.barcode.length > 0
              : true
          })
        }
      }
    }

    const v$ = useVuelidate(validations.form, state.form)

    const isValid = computed(() => !v$.value.$invalid)

    const errors = computed(() => {
      const errorObj = {}
      v$.value.$errors.forEach((err) => {
        errorObj[err.$property] = err.$message
      })

      return errorObj
    })

    // const options = computed(() => {
    //   const kioskModule = store.state.schools.activeSchoolModules.find(
    //     (module) => module.name === "Kiosk"
    //   )
    //   return kioskModule ? Boolean(kioskModule.pivot.status) : false
    // })

    const schoolDomains = computed(() => {
      return props.activeKiosk && props.activeKiosk.school
        ? props.activeKiosk.school.active_domains
        : []
    })

    watch(
      () => state.form.qrCode,
      (newCode, oldCode) => {
        if (newCode != oldCode && newCode.length > 0) {
          loginWithcode("qrCode")
        }
      }
    )

    watch(
      () => state.form.barcode,
      (newCode, oldCode) => {
        if (newCode != oldCode && newCode.length > 0) {
          loginWithcode("barcode")
        }
      }
    )

    onBeforeMount(() => {
      const schoolDomain = schoolDomains.value ? schoolDomains.value[0] : ""
      state.activeDomain =
        props.activeKiosk &&
        props.activeKiosk.defaultDomain != "INVALID DOMAIN NAME"
          ? props.activeKiosk.defaultDomain
          : schoolDomain

      if (props.activeKiosk.loginType) {
        setLoginType(props.activeKiosk.loginType)
      }
    })

    const login = (loginType) => {
      resetResponseMessages()
      if (isValid.value) {
        state.isLoading = true
        let params = {}
        if (loginType == "standart") {
          const passcode = state.form.code
          params = {
            email: state.activeDomain
              ? state.form.email + "@" + state.activeDomain
              : state.form.email,
            code: passcode,
            school_id: props.activeKiosk.school_id
          }
        } else {
          params = {
            code: state.form[loginType],
            school_id: props.activeKiosk.school_id
          }
        }
        store
          .dispatch("kiosks/userLogin", { ...params, type: loginType })
          .then((response) => {
            const data = response.data.data
            if (data) {
              setSuccessResponse("Successfully logged.")
              store.commit("kiosks/SET_KIOSK_ACCESS_TOKEN", data.access_token)
              store.commit("kiosks/SET_ACTIVE_PASS", data.active_pass)
              getPass()
              emit("userLoggedIn")
            }
            state.isLoading = false
          })
          .catch((err) => {
            if (err && err.response && err.response.data) {
              const response = err.response.data
              setErrorResponse(response.message, response.errors)
            }
            state.isLoading = false
          })
      } else {
        v$.value.$touch()
      }
    }

    const getPass = () => {
      store.dispatch("kiosks/getPasses").then((res) => {
        const data = res.data
        if (data && data.pass) {
          store.commit("kiosks/SET_ACTIVE_PASS", data.pass)
        }
      })
    }

    const loginWithcode = (loginType) => {
      clearInterval(state.codeLoginTimeOut)
      state.codeLoginTimeOut = setTimeout(() => {
        login(loginType)
      }, 500)
    }

    const setSuccessResponse = (message) => {
      state.reqResponse = {
        type: "success",
        message: message ? message : "Success!",
        errors: {}
      }
    }

    const setErrorResponse = (message, errors) => {
      state.reqResponse = {
        type: "danger",
        message: message ? message : "Something went wrong!",
        errors: errors ? errors : {}
      }
    }

    const resetResponseMessages = () => {
      state.reqResponse = {
        type: "",
        message: "",
        errors: {}
      }
    }

    const resetForm = () => {
      state.form = {
        email: null,
        code: null,
        qrCode: "",
        barcode: ""
      }
      setTimeout(() => {
        v$.value.$reset()
      }, 100)
    }

    const goToMainScreen = () => {
      emit("tabChanged", "mainScreen")
      store.commit("kiosks/SET_CREATE_NEW_PASS_DATA", null)
    }

    const setLoginType = (type) => {
      resetResponseMessages()
      resetForm()
      state.auth_type = type
      for (const key in state.authTypes) {
        if (Object.hasOwnProperty.call(state.authTypes, key)) {
          state.authTypes[key].isVisible = key == type
        }
      }
    }

    const launchScanner = () => {
      state.scannerModalVisible = true
    }

    const closeScanner = () => {
      state.scannerModalVisible = false
      state.isScannerLoading = true
    }

    const onDetect = (detectedCode) => {
      closeScanner()
      state.form.barcode = detectedCode[0].rawValue
    }
    const onScannerLaunched = () => {
      state.isScannerLoading = false
    }

    return {
      state,
      isValid,
      errors,
      schoolDomains,
      login,
      loginWithcode,
      goToMainScreen,
      setLoginType,
      launchScanner,
      closeScanner,
      onDetect,
      onScannerLaunched
    }
  }
}
</script>
